import Listen from '../components/Listen'

// This page shows components related to listen
// Users: routes/publicRoutes.js
const ListenPage = () => {
    return (
        <div>
            <Listen />
        </div>
    )
}

export default ListenPage