import Broadcast from '../components/Broadcast'

// This page shows components related to broadcast
// Users: routes/pageRoutes/pageRoutes.js
const BroadcastPage = () => {
    return (
        <div>
            <Broadcast />
        </div>
    )
}

export default BroadcastPage